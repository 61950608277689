import * as d3 from '../../libraries/d3/index.js';

export default class Locator {
  constructor(scale) {
    this._scale = scale;
    this._state = null;
    this._data = {};
    this._container = d3
      .select(document.createElementNS(d3.namespace('svg').space, 'g'))
      .attr('locator-group', '');
  }

  get container() {
    return this._container;
  }

  show() {
    this._container.node().style.display = 'block';
  }

  hide() {
    this._container.node().style.display = 'none';
  }

  update(data, state) {
    this._state = state;
    this._data = data;

    this._container.selectAll('circle').remove();

    this._container.append('circle')
      .attr('r', 10)
      .attr('locator-glow', '')
      .attr('fill', data.color)
      .attr('cx', '0')
      .attr('cy', '0')
      .attr('opacity', '0.4');

    this._container.append('circle')
      .attr('r', 6)
      .attr('locator', '')
      .attr('cx', '0')
      .attr('cy', '0')
      .attr('stroke', data.color);
  }

  move(position) {
    const closestDataOnXAxis = this._scale.x.getClosestData(position.x).toString();
    const dataset = this._data.summarizedData ? this._data.summarizedData : this._data.data;
    const locatorData = dataset.filter(data => data.x === closestDataOnXAxis)[0];

    if (!locatorData || locatorData.y === null) {
      this.hide();
      return;
    }

    this.show();
    const offset = this._state.domainType === 'discrete' ? this._scale.x.scale.bandwidth() / 2 : 0;
    const xPos = this._scale.x.getPositionOf(locatorData.x) + offset;
    const yPos = this._scale.y.scale(locatorData.y);
    this._container.attr('transform', `translate(${xPos},${yPos})`);
  }
}
