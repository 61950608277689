import { HTMLCustomElement, EventEmitter } from '@emartech/ui-framework-utils';
import { ResizeObserver } from '@emartech/ui-framework-utils';

import * as d3 from '../../libraries/d3/index.js';
import SeriesStore from '../../libraries/series-store/index.js';
import AxisX from '../../parts/axes/x/index.js';
import AxisYLeft from '../../parts/axes/y/axis-y-left.js';
import AxisYRight from '../../parts/axes/y/axis-y-right.js';
import Tooltip from '../../parts/tooltip/index.js';
import Baseline from '../../parts/baseline/index.js';
import Gridline from '../../parts/gridline/index.js';
import Line from '../../parts/diagram/line/index.js';
import Area from '../../parts/diagram/area/index.js';
import Stack from '../../parts/diagram/stack/index.js';
import Group from '../../parts/diagram/group/index.js';
import Column from '../../parts/diagram/column/index.js';
import InteractionLayer from '../../parts/interaction-layer/index.js';
import Scale from '../../libraries/scale/index.js';
import constants from '../../constants.js';

const DEFAULT_PADDING = 10;

const parseJSON = json => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return false;
  }
};

class EcChart extends HTMLCustomElement {
  init() {
    this._isRendering = false;
    this._padding = {
      top: DEFAULT_PADDING + 5,
      right: DEFAULT_PADDING,
      bottom: DEFAULT_PADDING,
      left: DEFAULT_PADDING
    };
    this._offset = { top: 0, right: 0, bottom: 15, left: 0 };
    this._strategy = { min: null, max: null };
    this._formatX = '';
    this._currency = '';
    this._axisXDateFormat = 'mmd';
    this._tooltipXDateFormat = constants.tooltipXDateFormat;
    this._axisYFormatter = null;
    this._axisXFormatter = null;
    this._tooltipHeaderFormatter = null;
    this._domainType = 'discrete';
    this._separateYAxes = false;
    this._scales = {};
    this._fixedLeftOffset = null;
    this._fixedRightOffset = null;

    this._dom = {};
    this._dom.svg = d3.select(document.createElementNS(d3.namespace('svg').space, 'svg')).attr('width', '100%');
    this._dom.canvas = d3.select(document.createElementNS(d3.namespace('svg').space, 'g')).attr('canvas', '');
    this._dom.charts = d3.select(document.createElementNS(d3.namespace('svg').space, 'g')).attr('charts', '');
    this._dom.loader = document.createElement('e-spinner');
    this._dom.loader.setAttribute('data-fullscreen', 'true');

    this._seriesStore = new SeriesStore();
    this.addEventListener('chartseries.update', this._updateSeries);
    this._charts = {};

    this._axis = {};
    this._axis.x = new AxisX();
    this._axis.yLeft = new AxisYLeft();
    this._axis.yRight = new AxisYRight();
    this._scale = {
      x: this._axis.x.scale,
      y: this._axis.yLeft.scale
    };

    this._customTooltip = null;

    this._emitter = new EventEmitter();
    this._tooltip = new Tooltip(this._seriesStore, this._scale, this._emitter);
    this._baseline = new Baseline();
    this._gridline = new Gridline();
    this._interactionLayer = new InteractionLayer(this._emitter);

    this._resizeObserver = new ResizeObserver(this._resizeObserverHandler.bind(this));
    this._hasScheduledRendering = false;
    this._animationDisabled = false;
  }

  connectedCallback() {
    this._buildCanvas();
    this._interactionLayer.build();
    this._resizeObserver.observe(this);
  }

  disconnectedCallback() {
    this._resizeObserver.unobserve(this);
    this._tooltip.remove();
  }

  static get observedAttributes() {
    return ['height', 'locale', 'strategy-min', 'strategy-max', 'axis-x-hidden', 'axis-y-hidden', 'baseline-hidden',
      'format-x', 'domain-type', 'separate-y-axes', 'currency', 'axis-x-date-format', 'custom-tooltip',
      'tooltip-x-date-format', 'padding-left', 'padding-right', 'loading', 'animation-disabled'];
  }

  set height(value) {
    const height = parseInt(value, 10);
    if (!height) {
      return;
    }

    this._dom.svg.attr('height', height);
    this._update();
  }

  set locale(value) {
    this._axis.x.locale = value;
  }

  set strategyMin(value) {
    this._strategy.min = value;
    this._update();
  }

  set strategyMax(value) {
    this._strategy.max = value;
    this._update();
  }

  set axisXHidden(value) {
    const isHidden = super._convertAttributeToBoolean(value);
    this._axis.x.hidden = isHidden;
    this._padding.bottom = isHidden ? 0 : DEFAULT_PADDING;
    this._update();
  }

  set axisYHidden(value) {
    const isHidden = super._convertAttributeToBoolean(value);
    this._axis.yLeft.hidden = isHidden;
    this._axis.yRight.hidden = isHidden;
    this._gridline.hidden = isHidden;
    this._update();
  }

  set baselineHidden(value) {
    const isHidden = super._convertAttributeToBoolean(value);
    this._baseline.hidden = isHidden;
    this._update();
  }

  set axisYFormatter(value) {
    this._axisYFormatter = value;
    this._update();
  }

  set axisXFormatter(value) {
    this._axisXFormatter = value;
    this._update();
  }

  set tooltipHeaderFormatter(value) {
    this._tooltipHeaderFormatter = value;
    this._update();
  }

  set formatX(value) {
    this._formatX = value;
    this._update();
  }

  set domainType(value) {
    if (['discrete', 'time'].indexOf(value) < 0) {
      return;
    }

    this._domainType = value;
    this._update();
  }

  set currency(value) {
    this._currency = value;
    this._update();
  }

  set axisXDateFormat(value) {
    this._axisXDateFormat = value;
    this._update();
  }

  set tooltipXDateFormat(value) {
    this._tooltipXDateFormat = value;
    this._update();
  }

  set customTooltip(value) {
    this._customTooltip = typeof value === 'string' ? parseJSON(value) : value;
    this._update();
  }

  set paddingLeft(value) {
    const number = parseInt(value);
    if (!Number.isNaN(number)) {
      this._fixedLeftOffset = number;
      this._offset.left = this._fixedLeftOffset;
    } else {
      this._fixedLeftOffset = null;
      this._offset.left = this._axis.yLeft.width;
    }
    this._update();
  }

  set paddingRight(value) {
    const number = parseInt(value);
    if (!Number.isNaN(number)) {
      this._fixedRightOffset = number;
      this._offset.right = this._fixedRightOffset;
    } else {
      this._fixedRightOffset = null;
      this._offset.right = this._axis.yRight.width;
    }
    this._update();
  }

  set loading(value) {
    this._loading = super._convertAttributeToBoolean(value);

    if (this._loading) {
      this.appendChild(this._dom.loader);
    } else {
      this._removeLoader();
    }
  }

  set animationDisabled(value) {
    this._animationDisabled = super._convertAttributeToBoolean(value);
    this._update();
  }

  get state() {
    return {
      width: this.width,
      height: this.height,
      canvasWidth: this.width - this._offset.left - this._offset.right,
      canvasHeight: this.height - this._offset.bottom,
      padding: this._padding,
      offset: this._offset,
      strategy: this._strategy,
      domainType: this._domainType,
      formatX: this._calculateFormatX(),
      currency: this._currency,
      axisXDateFormat: this._axisXDateFormat,
      tooltipXDateFormat: this._tooltipXDateFormat,
      axisXFormatter: this._axisXFormatter,
      axisYFormatter: this._axisYFormatter,
      tooltipHeaderFormatter: this._tooltipHeaderFormatter,
      showTooltip: !!this._seriesStore.dataOrderedByUpdateEvent.filter(series => !series.hiddenInTooltip).length,
      customTooltip: this._customTooltip,
      baseline: 0,
      fixedLeftOffset: this._fixedLeftOffset,
      fixedRightOffset: this._fixedRightOffset,
      animationDisabled: this._animationDisabled
    };
  }

  get width() {
    return this.getBoundingClientRect().width - (this._padding.left + this._padding.right);
  }

  get height() {
    return this.getBoundingClientRect().height - (this._padding.top + this._padding.bottom);
  }

  get separateYAxes() {
    return this._separateYAxes;
  }

  set separateYAxes(value) {
    this._separateYAxes = super._convertAttributeToBoolean(value);
    this._render();
  }

  get charts() {
    return this._charts;
  }

  _createScales() {
    if (!this.separateYAxes) {
      return;
    }
    this._scales = {};

    this._seriesStore.visible.forEach((options) => {
      this._scales[options.uuid] = {
        x: this._scale.x,
        y: new Scale('y')
      };
    });
  }

  _updateScales() {
    if (!this.separateYAxes) {
      return;
    }

    const state = this.state;
    this._seriesStore.visible.forEach((options) => {
      this._scales[options.uuid].y.update(options.data.map(d => d.y), state);
    });
  }

  _calculateFormatX() {
    const hasToFormat = this._formatX !== 'none';
    const isDate = this._domainType === 'time' || this._formatX === 'date';
    return hasToFormat && isDate ? 'date' : 'none';
  }

  _buildCanvas() {
    this._dom.svg.node().appendChild(this._dom.canvas.node());
    this._dom.canvas.node().appendChild(this._gridline.container.node());
    this._dom.canvas.node().appendChild(this._baseline.container.node());
    this._dom.canvas.node().appendChild(this._dom.charts.node());
    this._dom.canvas.node().appendChild(this._axis.yLeft.container.node());
    this._dom.canvas.node().appendChild(this._axis.yRight.container.node());
    this._dom.canvas.node().appendChild(this._axis.x.container.node());
    this._dom.canvas.node().appendChild(this._interactionLayer.container.node());
    this.appendChild(this._dom.svg.node());
    this.appendChild(this._dom.loader);
  }

  _updateSeries(event) {
    if (!this._loading) { this._removeLoader(); }

    this._seriesStore.update(event.detail);
    event.target.addEventListener('chartseries.delete', this._deleteSeries.bind(this));
    this._scheduleRender();
  }

  _deleteSeries(event) {
    this._stopTimer(event.detail.uuid);
    this._cleanup(event.detail.uuid);
    this._update();
  }

  _removeLoader() {
    if (!this._dom.loader.parentNode) { return; }

    this.removeChild(this._dom.loader);
  }

  _isRenderPossible() {
    return this.parentNode && this.width > 0 && this.height > 0 && !this._hasDifferentDiscreteValues();
  }

  _cleanup(uuid) {
    this._seriesStore.delete(uuid);
    delete this._charts[uuid];
    this._dom.charts.select(`g[uuid="${uuid}"]`).remove();
  }


  _scheduleRender() {
    if (this._hasScheduledRendering) {
      return;
    }

    this._hasScheduledRendering = true;
    requestAnimationFrame(() => {
      this._hasScheduledRendering = false;
      this._render();
    });
  }

  _render() {
    if (!this._isRenderPossible()) {
      return;
    }

    this._createScales();
    this._stopTimers();

    this._updateScales();
    this._updateAxes();
    this._renderCharts();
    this._updateTooltip();
    this._updateBaseline();
    this._updateGridline();
    this._updateInteractionLayer();
  }

  _renderCharts() {
    this._dom.charts.selectAll('g').remove();

    const state = this.state;
    const charts = { line: Line, area: Area, column: Column, group: Group, stack: Stack };

    this._seriesStore.visible.forEach(options => {
      const scale = this._getScale(options);
      options.animationDisabled = state.animationDisabled;
      this._charts[options.uuid] = new charts[options.type](options, scale, state, this._emitter, this._seriesStore);
      this._dom.charts.node().appendChild(this._charts[options.uuid].container.node());
    });
  }

  _getScale(options) {
    if (this.separateYAxes) {
      return this._scales[options.uuid];
    } else if (options.axisYRightGroup !== null) {
      return {
        x: this._scale.x,
        y: this._axis.yRight.scale
      };
    }

    return this._scale;
  }

  _updateAxes() {
    this._updateAxisY();
    this._updateAxisX();
  }

  _update() {
    if (!this._isRenderPossible() || this._hasScheduledRendering) {
      return;
    }

    this._updateScales();
    this._updateAxes();
    this._updateCharts();
    this._updateTooltip();
    this._updateGridline();
    this._updateBaseline();
    this._updateInteractionLayer();
  }

  _updateCharts() {
    const state = this.state;
    const seriesStoreData = this._seriesStore.data;
    Object.keys(this._charts).forEach(uuid => {
      const options = seriesStoreData[uuid];
      this._charts[uuid].update(options, state);
    });
  }

  _updateAxisY() {
    this._axis.yLeft.update(this._seriesStore, this.state);
    this._offset.left = this._fixedLeftOffset === null ? this._axis.yLeft.width : this._fixedLeftOffset;
    this._axis.yRight.update(this._seriesStore, this.state);
    this._offset.right = this._fixedRightOffset === null ? this._axis.yRight.width : this._fixedRightOffset;
    this._axis.yLeft.update(this._seriesStore, this.state);

    const x = this._offset.left + this._padding.left;
    const y = this._padding.top;
    this._dom.canvas.attr('transform', `translate(${x}, ${y})`);
  }

  _updateAxisX() {
    this._axis.x.update(this._seriesStore, this.state);

    const y = this.height - (this._offset.top + this._offset.bottom);
    this._axis.x.container.attr('transform', `translate(0, ${y})`);
  }

  _updateTooltip() {
    this._tooltip.update(this.state);
  }

  _updateBaseline() {
    this._baseline.update(this._scale, this.state);
  }

  _updateGridline() {
    this._gridline.update(this._scale.y, this.state);
  }

  _updateInteractionLayer() {
    this._interactionLayer.update({ width: this.state.canvasWidth, height: this.state.canvasHeight });
  }

  _stopTimers() {
    Object.keys(this._charts).forEach(uuid => this._stopTimer(uuid));
  }

  _stopTimer(uuid) {
    if (this._charts[uuid]) {
      this._charts[uuid].disconnect();
    }
  }

  _hasDifferentDiscreteValues() {
    return this._domainType === 'discrete' && !this._seriesStore.hasSameVisibleXValues;
  }

  _resizeObserverHandler() {
    if (!this._isRenderPossible()) {
      return;
    }

    this._createScales();
    this._updateScales();
    this._updateAxes();

    if (!this._dom.charts.selectAll('g').nodes().length) {
      this._renderCharts();
    } else {
      this._updateCharts();
    }

    this._updateTooltip();
    this._updateBaseline();
    this._updateGridline();
    this._updateInteractionLayer();
  }
}

window.customElements.define('ec-chart', EcChart);
