import { EcSeriesLine } from '../series-line/index.js';

class EcSeriesArea extends EcSeriesLine {
  init() {
    super.init();

    this._state = Object.assign({}, this._state, { type: 'area' });
  }
}

window.customElements.define('ec-series-area', EcSeriesArea);
