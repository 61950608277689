import { dateUtils } from '@emartech/ui-framework-utils';

import * as d3 from '../../../libraries/d3/index.js';
import Scale from '../../../libraries/scale/index.js';

const DEFAULT_TICK_PADDING = 10;

const calculateLongestTick = tickElements => {
  const longestTickWidth = Math.max(...tickElements.nodes().map(tick => tick.getBoundingClientRect().width));
  return isFinite(longestTickWidth) ? Math.ceil(longestTickWidth) : 0;
};

class AxisX {
  constructor() {
    this._tickPadding = DEFAULT_TICK_PADDING;
    this._values = null;
    this._state = null;
    this._scale = new Scale('x');
    this._container = d3.select(document.createElementNS(d3.namespace('svg').space, 'g')).attr('axis-x', '');
    this._hidden = false;

    this._dateFormatConfig = this._getDefaultDateFormatConfig();
    this._getDateFormatConfigFromConfigStore();
  }

  get scale() {
    return this._scale;
  }

  get container() {
    return this._container;
  }

  set locale(value) {
    this._dateFormatConfig.language = value;
    this._draw();
  }

  set hidden(value) {
    this._hidden = value;
    this._draw();
  }

  update(series, state) {
    this._state = state;
    this._values = series.xValues;
    this._scale.columnPadding = series.visible.reduce((prev, current) => current.padding || prev, 0);
    this._scale.update(this._values, this._state);

    this._decorateContainerWithType();
    this._draw();
  }

  _getDefaultDateFormatConfig() {
    const hasDefaultConfig = window.e && window.e.defaultConfig;
    const { dateFormat, timeFormat, language } = hasDefaultConfig ? window.e.defaultConfig : {
      dateFormat: 'm-d-Y',
      timeFormat: 'H:i:s',
      language: 'en'
    };

    return { dateFormat, timeFormat, language };
  }

  _getDateFormatConfigFromConfigStore() {
    if (!window.e) { return; }

    window.e.utils.getConfig().then(config => {
      const { dateFormat, timeFormat, language } = config;
      this._dateFormatConfig = { dateFormat, timeFormat, language };
      this._draw();
    });
  }

  _getValues() {
    return this._state.domainType === 'discrete' ?
      this._values :
      this._values.map(data => dateUtils.parse(data)).sort((a, b) => a - b);
  }

  _getRawValues() {
    return this._state.domainType === 'discrete' ?
      this._values :
      this._values.slice().sort((a, b) => dateUtils.parse(a) - dateUtils.parse(b));
  }

  _decorateContainerWithType() {
    this._container.attr('type', this._state.domainType);
  }

  _draw() {
    if (!this._scale.scale || this._hidden || !this._values.length) {
      this._container.selectAll('.tick').remove();
      return;
    }

    this._drawTicks();
    this._removeTicklines();
  }

  _drawTicks() {
    this._container.call(
      d3.axisBottom(this._scale.scale)
        .tickValues(this._getValues())
        .tickFormat(value => this._formatTickLabel(value))
    );

    this._filterOverlappingLabels();
  }

  _filterOverlappingLabels() {
    this._container.call(
      d3.axisBottom(this._scale.scale)
        .tickValues(this._getTickValues())
        .tickFormat(value => this._formatTickLabel(value))
    );
  }

  _formatTickLabel(value) {
    if (this._state.axisXFormatter) {
      return this._state.axisXFormatter(value);
    }

    const valueAsString = typeof value === 'string' ? value : value.toISOString();

    return this._state.formatX === 'date' ?
      dateUtils.formatByName(valueAsString, this._state.axisXDateFormat, this._dateFormatConfig) : value;
  }

  _removeTicklines() {
    this._container.select('.domain').remove();
    this._container.selectAll('line').remove();
  }

  _getTickValues() {
    const labelElements = this._container.selectAll('.tick text');
    const labelWidth = calculateLongestTick(labelElements) + this._tickPadding;
    const valueWidth = this._state.canvasWidth / this._values.length;
    const valueLabelRatio = Math.ceil(labelWidth / valueWidth);

    const firstLabelIndex = this._getFirstLabelIndex(labelWidth);
    const lastLabelIndex = this._getLastLabelIndex(labelWidth);

    return firstLabelIndex > -1 ? this._getVisibleLabels(firstLabelIndex, lastLabelIndex, valueLabelRatio) : [];
  }

  _getFirstLabelIndex(labelWidth) {
    const values = this._getRawValues();
    return values.reduce((prevValue, value, index) => {
      const roomBeforeLabel = this._scale.getCenterPositionOf(value) + this._state.offset.left;
      return prevValue === -1 && labelWidth / 2 <= roomBeforeLabel ? index : prevValue;
    }, -1);
  }

  _getLastLabelIndex(labelWidth) {
    const values = this._getRawValues();
    return values.reduce((prevValue, value, index) => {
      const roomAfterLabel = this._state.canvasWidth - this._scale.getCenterPositionOf(value) +
        this._state.offset.right;
      return labelWidth / 2 <= roomAfterLabel ? index : prevValue;
    }, -1);
  }

  _getVisibleLabels(firstLabelIndex, lastLabelIndex, valueLabelRatio) {
    const values = this._getValues();
    let visibleValues = [];
    for (let i = firstLabelIndex; i <= lastLabelIndex; i += valueLabelRatio) {
      visibleValues.push(values[i]);
    }
    return visibleValues;
  }

}

export default AxisX;
