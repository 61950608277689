import * as d3 from '../d3/index.js';

const MODIFIER = {
  min: 0.5,
  max: 0.05
};

class DomainCalculator {
  constructor(strategy = { min: null, max: null }, data) {
    this._strategy = strategy;
    [this._originalMinimum, this._originalMaximum] = d3.extent(data);
  }

  getExtent() {
    const minimum = this._getMinimum();
    const maximum = this._getMaximum();
    return this._applyCorrectionForConstantZeroData([minimum, maximum]);
  }

  _getMinimum() {
    if (this._strategy.min === 'reasonable') {
      return this._getReasonableMinimum();
    } else {
      return Math.min(0, this._originalMinimum);
    }
  }

  _getMaximum() {
    return this._strategy.max === 'reasonable' ? this._getReasonableMaximum() : this._originalMaximum;
  }

  _getReasonableMinimum() {
    const difference = this._originalMaximum - this._originalMinimum;
    return this._originalMinimum - (difference * MODIFIER.min);
  }

  _getReasonableMaximum() {
    const difference = this._originalMaximum - this._originalMinimum;
    const maximum = this._originalMaximum + (difference * MODIFIER.max);
    return maximum === 0 ? 0 : this._roundPretty(maximum);
  }

  _roundPretty(value) {
    const log = Math.floor(Math.log(Math.abs(value)) / Math.log(10));
    const fraction = log > 1 ? 2 : 1;
    return Math.ceil(value * fraction * Math.pow(10, -log)) / fraction / Math.pow(10, -log);
  }

  _applyCorrectionForConstantZeroData(extent) {
    return (extent[0] === 0 && extent[1] === 0) ? [0, 1] : extent;
  }
}

export default DomainCalculator;
