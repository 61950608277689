import { dateUtils } from '@emartech/ui-framework-utils';

import * as importedD3 from '../d3/index.js';
import DomainCalculator from '../domain-calculator/index.js';

const scaleTypes = {
  discrete: 'scaleBand',
  time: 'scaleTime'
};

class Scale {
  constructor(axis, d3 = importedD3) {
    this._state = {};
    this._axis = axis;
    this._scale = null;
    this._columnPadding = 0;
    this._data = [];
    this._d3 = d3;
  }

  update(data, state) {
    this._state = state;

    if (this._axis === 'x' && this._state.domainType === 'time') {
      this._data = data.map(value => this._normalizeValue(value)).sort((a, b) => a - b);
      this._rawData = data.slice().sort((a, b) => this._normalizeValue(a) - this._normalizeValue(b));
    } else {
      this._data = data;
      this._rawData = data;
    }

    const offsetLeft = this._state.offset.left || 0;
    const offsetRight = this._state.offset.right || 0;
    const offsetBottom = this._state.offset.bottom || 0;

    const xSize = this._state.width - offsetLeft - offsetRight;
    const ySize = this._state.height - offsetBottom;

    const scaleType = this._axis === 'x' ? scaleTypes[this._state.domainType] : 'scaleLinear';
    const range = this._axis === 'x' ? [0, xSize] : [ySize, 0];
    const domain = this._getDomain();

    this._scale = this._d3[scaleType]()
      .domain(domain)
      .range(range);

    if (scaleType === 'scaleBand') {
      const padding = this._columnPadding || this._data.length / xSize;
      this._scale
        .paddingInner(padding)
        .paddingOuter(padding / 2);
    }
  }

  getPositionOf(value) {
    return this._scale(this._normalizeValue(value));
  }

  getCenterPositionOf(value) {
    const offset = this._state.domainType === 'discrete' ? this._scale.bandwidth() / 2 : 0;
    return this._scale(this._normalizeValue(value)) + offset;
  }

  getClosestPosition(data) {
    return this._scale(this._normalizeValue(data));
  }

  getClosestIndex(position) {
    if (this._state.domainType === 'discrete') {
      const step = this._scale.step();
      const index = Math.floor((position / step));
      return Math.min(this._data.length - 1, index);
    }

    const mouseValue = this._scale.invert(position);
    const index = Math.max(1, this._d3.bisectLeft(this._data, mouseValue));
    const data0 = this._data[index - 1];
    const data1 = this._data[index];

    if (mouseValue - data0 > data1 - mouseValue) {
      return index;
    }

    return index - 1;
  }

  getClosestData(position) {
    return this._rawData[this.getClosestIndex(position)];
  }

  _getDomain() {
    if (this._axis === 'x') {
      return this._state.domainType === 'discrete' ? this._data : this._d3.extent(this._data);
    } else {
      const strategy = new DomainCalculator(this._state.strategy, this._data);
      return strategy.getExtent();
    }
  }

  _normalizeValue(value) {
    if (this._axis === 'x' && this._state.domainType === 'time') {
      return dateUtils.parse(value);
    }
    return value;
  }

  get containerWidth() {
    return this._state.width;
  }

  get containerHeight() {
    return this._state.height;
  }

  get offset() {
    return this._state.offset;
  }

  get padding() {
    return this._state.padding;
  }

  get scale() {
    return this._scale;
  }

  set columnPadding(value) {
    this._columnPadding = value;
  }
}

export default Scale;
