import { dateUtils } from '@emartech/ui-framework-utils';

import * as d3 from '../../../libraries/d3/index.js';
import Diagram from '../index.js';
import Line from '../line/index.js';

const calculateArea = (options, scale, height) => {
  const area = d3.area()
    .defined(data => data.y !== null)
    .x(data => scale.x.scale(dateUtils.parse(data.x)))
    .y0(data => scale.y.scale(data.y))
    .y1(data => data.y1 ? scale.y.scale(data.y1) : height);

  if (options.interpolate) {
    area.curve(d3.curveMonotoneX);
  }

  return area;
};

export default class Area extends Diagram {
  constructor(options, scale, state, emitter, seriesStore) {
    super(options, scale, state, emitter, seriesStore);

    this._line = null;
    this._path = null;

    this._render();
  }

  update(options, state) {
    super.update(options, state);

    const area = calculateArea(this._options, this._scale, state.canvasHeight);

    this._path
      .datum(this._options.summarizedData ? this._options.summarizedData : this._options.data)
      .attr('fill', this._options.color)
      .attr('area', '')
      .attr('d', area);

    this._line.update(this._options, this._state);

    return area;
  }

  disconnect() {
    this._path.interrupt();
    this._line.disconnect();
  }

  _render() {
    this._createContainer('area');

    this._path = this._container.append('path');
    this._line = new Line(this._options, this._scale, this._state, this._emitter, this._seriesStore);
    this._container.node().appendChild(this._line.container.node());

    const dataset = this._options.summarizedData ? this._options.summarizedData : this._options.data;

    const area = this.update(this._options, this._state);
    const startData = dataset.map(data => ({
      x: data.x,
      y: this._scale.y.scale.invert(this._state.canvasHeight)
    }));

    this._path
      .transition()
      .duration(this._animationDuration)
      .ease(d3.easeCubicInOut)
      .attrTween('d', () => {
        const interpolator = d3.interpolateArray(startData, dataset);
        return time => area(interpolator(time));
      });
  }
}
