import { EcSeries } from '../series/index.js';

export class EcSeriesColumn extends EcSeries {
  init() {
    super.init();

    this._state = Object.assign({}, this._state, { type: 'column' });
  }

  static get observedAttributes() {
    return ['highlight-last', 'padding'].concat(super.observedAttributes);
  }

  set padding(value) {
    this._state.padding = typeof value === 'string' ? parseFloat(value) : value;
    this._dispatchEvent();
  }

  set highlightLast(value) {
    this._state.highlightLast = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

}

window.customElements.define('ec-series-column', EcSeriesColumn);
