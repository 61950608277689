const isEqualArray = (firstArray, secondArray) => {
  return firstArray.length === secondArray.length &&
    firstArray.every((value, index) => secondArray[index] === value);
};

const unique = list => list.reduce(
  (previous, current) => previous.indexOf(current) > -1 ? previous : [...previous, current],
  []
);

class SeriesStore {
  constructor() {
    this._data = {};
  }

  get dataOrderedByUpdateEvent() {
    return Object.keys(this._data).reduce((prev, current) => {
      if (this._data[current].parts && this._data[current].parts.length) {
        prev.push(this._data[current]);
        prev = prev.concat(this._data[current].parts);
      } else {
        prev.push(this._data[current]);
      }

      return prev;
    }, []);
  }

  get data() {
    return this._orderByZIndex(this._data);
  }

  get summaryForLeftYAxis() {
    const seriesForLeftYAxis = this.visible.filter(series => series.axisYRightGroup === null);
    return this._concatData(seriesForLeftYAxis);
  }

  get summaryForRightYAxis() {
    const seriesForRightYAxis = this.visible.filter(series => series.axisYRightGroup !== null);
    return this._concatData(seriesForRightYAxis);
  }

  get charts() {
    const data = this.data;
    return Object.keys(data).reduce((prev, current) => {
      prev.push(data[current]);
      return prev;
    }, []);
  }

  get visible() {
    return this.charts.filter(chart => chart.visible && chart.type !== 'series' && chart.data.length !== 0);
  }

  get formatY() {
    return this.visible.reduce((prev, current) => current.formatY || prev, false);
  }

  get xValues() {
    if (!this.charts.length) {
      return [];
    }
    const jointXValues = this.charts.reduce((previous, current) => previous.concat(this._getXValues(current)), []);
    return unique(jointXValues);
  }

  get hasSameVisibleXValues() {
    const visibleSeries = this.visible;
    if (visibleSeries.length < 2) {
      return true;
    }
    const firstSeriesXValues = this._getXValues(visibleSeries[0]).sort();
    return visibleSeries.slice(1).every(series => {
      return isEqualArray(this._getXValues(series).sort(), firstSeriesXValues);
    });
  }

  update(options) {
    this._data[options.uuid] = options;
  }

  delete(uuid) {
    delete this._data[uuid];
  }

  hasHighlightedSeries() {
    return Object.values(this._data).some(series => {
      return series.highlight || series.parts?.some(part => part.highlight);
    });
  }

  _orderByZIndex(series) {
    const sortedKeys = Object.keys(series).sort((a, b) => series[a].zIndex - series[b].zIndex);
    return sortedKeys.reduce((prev, current) => {
      prev[current] = series[current];
      return prev;
    }, {});
  }

  _getXValues(series) {
    return series.data.map(data => data.x);
  }

  _concatData(list) {
    return list.reduce((prev, current) => prev.concat(current.data), []);
  }
}

export default SeriesStore;
