import { EcSeries } from '../series/index.js';

export class EcSeriesLine extends EcSeries {
  init() {
    super.init();

    const state = {
      type: 'line',
      interpolate: true,
      marker: false,
      lineStyle: 'solid'
    };

    this._state = Object.assign({}, this._state, state);
  }

  static get observedAttributes() {
    return ['interpolate', 'marker', 'line-style'].concat(super.observedAttributes);
  }

  set interpolate(value) {
    this._state.interpolate = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set marker(value) {
    this._state.marker = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set lineStyle(value) {
    const validValues = ['solid', 'dashed'];
    this._state.lineStyle = validValues.indexOf(value) !== -1 ? value : 'solid';
    this._dispatchEvent();
  }
}

window.customElements.define('ec-series-line', EcSeriesLine);
