import { EcSeries } from '../series/index.js';

class EcSeriesGroup extends EcSeries {
  init() {
    super.init();

    this._parts = [];
    this._data = [];
    this._state = Object.assign({ parts: this._parts }, this._state, { type: 'group', padding: 0.5 });

    this.addEventListener('group.update', this._updateParts);
    this._deleteParts = this._deleteParts.bind(this);
  }

  static get observedAttributes() {
    return ['padding'].concat(super.observedAttributes);
  }

  set padding(value) {
    this._state.padding = parseFloat(value);
    this._dispatchEvent();
  }

  _updateParts(event) {
    const index = this._parts.findIndex(part => part.uuid === event.detail.uuid);
    const options = Object.assign({}, event.detail);

    if (index === -1) {
      this._parts.push(options);
    } else {
      this._parts[index] = options;
    }

    this._parts.sort((a, b) => (a.zIndex > b.zIndex) ? 1 : -1);

    event.target.addEventListener('group.delete', this._deleteParts);

    this._updateState();
  }

  _deleteParts(event) {
    const index = this._parts.findIndex(part => part.uuid === event.detail.uuid);
    this._parts.splice(index, 1);
    this._updateState();
  }

  _updateState() {
    if (!this._parts.length) {
      this._dispatchEvent();
      return;
    }

    const maximumData = this._parts[0].data.map((data, dataIndex) => {
      const yMaximum = this._calculateMaximum(dataIndex);
      return { x: data.x, y: yMaximum };
    });

    const visibleParts = this._parts.filter(part => part.visible);
    this._parts.forEach(part => part.groupSize = visibleParts.length);
    visibleParts.forEach((part, index) => part.xOffset = index);

    this._state.data = maximumData;
    this._state.parts = this._parts;

    this._dispatchEvent();
  }

  _calculateMaximum(dataIndex) {
    return this._parts.reduce((currentMaximum, part) => {
      if (part.visible && part.data[dataIndex].y > currentMaximum) { return part.data[dataIndex].y; }
      return currentMaximum;
    }, 0);
  }
}

window.customElements.define('ec-series-group', EcSeriesGroup);
