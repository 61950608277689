import config from '../../config/index.js';

const CURRENCY_SYMBOLS = {
  EUR: '€',
  USD: '$',
  PHP: '₱',
  GBP: '£',
  AUD: '$',
  SGD: '$',
  HKD: '$',
  ARS: '$',
  MXN: '$'
};

class CurrencyFormatter {
  constructor() {
    this._code = '';
    this._symbol = config.defaultCurrencySymbol;
  }

  transform(value) {
    return `${this._symbol}${value || 0}${this._code}`;
  }

  _definedSymbol(currency) {
    return CURRENCY_SYMBOLS[currency] || '';
  }

  set currency(value) {
    this._code = value.length === 3 && this._definedSymbol(value) === '' ? ` ${value}` : '';
    this._symbol = value.length === 1 ? value : this._definedSymbol(value);

    if (value.length === 0) {
      this._code = '';
      this._symbol = config.defaultCurrencySymbol;
    }
  }
}

export default CurrencyFormatter;
