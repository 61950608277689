import { formatNumber } from '@emartech/ui-framework-utils';

import * as d3 from '../d3/index.js';
import constants from '../../constants.js';
import CurrencyFormatter from '../currency-formatter/index.js';

const defaultFormat = {
  y: '',
  x: 'date'
};

class Formatter {
  constructor() {
    this._locale = 'en';
    this._formatY = defaultFormat.y;
    this._formatX = defaultFormat.x;
    this._currencyFormatter = new CurrencyFormatter();
    this._dateFormat = constants.tooltipXDateFormat;
    this._format = {
      'decimal': '.',
      'thousands': ',',
      'grouping': [3],
      'currency': ['', '']
    };
  }

  set formatY(value) {
    this._formatY = value || defaultFormat.y;
  }

  get formatY() {
    return this._formatY;
  }

  set formatX(value) {
    this._formatX = value || defaultFormat.x;
  }

  get formatX() {
    return this._formatX;
  }

  set locale(value) {
    this._locale = value;
  }

  set currency(value) {
    this._currencyFormatter.currency = value;
  }

  set dateFormat(value) {
    this._dateFormat = value;
  }

  format(axisFormat, value) {
    if (!axisFormat || typeof axisFormat === 'string') {
      const formattedValue = axisFormat ?
        d3.formatLocale(this._format).format(axisFormat)(value) :
        formatNumber({ precision: -1 }, value);

      if (axisFormat.indexOf('$') > -1) {
        return this._currencyFormatter.transform(formattedValue);
      }

      return formattedValue;
    }

    return axisFormat(value);
  }

  transformX(data) {
    const isDate = this._formatX === 'date';
    return isDate ? this._convertDate(data) : data;
  }

  transformY(data) {
    return this.format(this._formatY, data);
  }

  _convertDate(data) {
    return `<e-time datetime="${data}" format="${this._dateFormat}"></e-time>`;
  }
}

export default Formatter;
