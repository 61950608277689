import AxisY from './base.js';

import { LABEL_ALIGN_TRANSFORM_Y } from './constants.js';

class AxisYLeft extends AxisY {
  get _identifierAttr() {
    return 'axis-y-left';
  }

  _dataForYAxis(series) {
    return series.summaryForLeftYAxis.map(d => d.y);
  }

  _alignScaleLabels() {
    this._container.attr('transform', `translate(${this._getContainerTranslateX()},0)`);
    this._container.selectAll('.tick text').attr('dy', LABEL_ALIGN_TRANSFORM_Y);
  }

  _getContainerTranslateX() {
    const whenFixedOffsetIsLarger = 0;
    const whenFixedOffsetIsSmaller = this.width - this._state.offset.left;
    return Math.max(whenFixedOffsetIsLarger, whenFixedOffsetIsSmaller);
  }
}

export default AxisYLeft;
