import * as d3 from '../../libraries/d3/index.js';
import config from '../../config/index.js';

const createGroup = (options, type, seriesStore) => {
  const group = d3.select(document.createElementNS(d3.namespace('svg').space, 'g'))
    .attr('uuid', options.uuid)
    .attr('type', type);

  const isMuted = type !== 'stack' && type !== 'group' && !options.highlight && seriesStore.hasHighlightedSeries();
  if (isMuted) { group.attr('class', 'ec-chart__series-muted'); }

  return group;
};

export default class Diagram {
  constructor(options, scale, state, emitter, seriesStore) {
    this._animationDuration =
      (options.animationDisabled || options.parent?.animationDisabled) ? 0 : config.animationDuration;
    this._container = null;
    this._options = options;
    this._scale = scale;
    this._state = state;
    this._emitter = emitter;
    this._seriesStore = seriesStore;
    this._hasMarkers = !!options.marker;
  }

  update(options, state) {
    this._options = options;
    this._state = state;
  }

  get container() {
    return this._container;
  }

  get options() {
    return this._options;
  }

  get seriesStore() {
    return this._seriesStore;
  }

  _createContainer(type) {
    this._container = createGroup(this._options, type, this._seriesStore);
  }
}
