import { HTMLCustomElement } from '@emartech/ui-framework-utils';
import CustomEvent from 'custom-event';

import createUuid from '../../libraries/create-uuid/index.js';
import calculateColor from '../../libraries/calculate-color/index.js';

const parseJSON = json => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return false;
  }
};

export class EcSeries extends HTMLCustomElement {
  init() {
    this._state = {
      uuid: createUuid(),
      data: false,
      type: 'series',
      color: calculateColor(),
      zIndex: 0,
      visible: true,
      name: '',
      formatY: '',
      hiddenInTooltip: false,
      axisYRightGroup: null,
      highlight: false
    };
  }

  connectedCallback() {
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('chartseries.delete', {
      bubbles: true,
      detail: this._state
    }));
  }

  static get observedAttributes() {
    return ['type', 'color', 'data', 'z-index', 'visible', 'name', 'format-y', 'hidden-in-tooltip',
      'axis-y-right-group', 'highlight'];
  }

  set type(value) {
    this._state.type = value;
    this._dispatchEvent();
  }

  set color(value) {
    this._state.color = calculateColor(value);
    this._dispatchEvent();
  }

  get data() {
    return this._state.data;
  }

  set data(value) {
    const data = typeof value === 'string' ? parseJSON(value) : value;
    if (!data) {
      return;
    }

    this._state.data = data;
    this._dispatchEvent();
  }

  set zIndex(value) {
    this._state.zIndex = value;
    this._dispatchEvent();
  }

  set visible(value) {
    this._state.visible = value === null ? true : super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set name(value) {
    this._state.name = value;
    this._dispatchEvent();
  }

  set formatY(value) {
    this._state.formatY = value;
    this._dispatchEvent();
  }

  set hiddenInTooltip(value) {
    this._state.hiddenInTooltip = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set highlight(value) {
    this._state.highlight = super._convertAttributeToBoolean(value);
    this._dispatchEvent();
  }

  set axisYRightGroup(value) {
    this._state.axisYRightGroup = value;
    this._dispatchEvent();
  }

  _dispatchEvent() {
    if (!this._state.data || !this.parentNode) {
      return;
    }

    const event = new CustomEvent('chartseries.update', {
      bubbles: true,
      detail: this._state
    });
    this.dispatchEvent(event);
  }
}

window.customElements.define('ec-series', EcSeries);
