import calculateColor from '../../libraries/calculate-color/index.js';

export default rows => {
  const classList = row => {
    return row.type && row.type === 'header' ? 'ec-table__row ec-table__row-header' : 'ec-table__row';
  };

  const formatValue = row => {
    if (typeof row.value === 'number' && row.valueFormat && row.valueFormat.type === 'number') {
      const options = row.valueFormat.options;
      const attributes = options ?
        Object.keys(options).map(attribute => `${attribute}="${options[attribute]}"`).join(' ') : '';
      return `<e-numeric value="${row.value}" ${attributes}></e-numeric>`;
    } else if (row.value) {
      return row.value;
    } else {
      return '';
    }
  };

  const color = row => row.color ? `style="color:${calculateColor(row.color)}"` : '';

  return `
    <ec-tooltip-content>
      <table class="ec-table">
        ${rows.map(row => `
          <tr class="${classList(row)}">
            <td class="ec-table__name">${row.label}</td>
            <td class="ec-table__data" ${color(row)}>${formatValue(row)}</td>
          </tr>`).join('')}
      </table>
    </ec-tooltip-content>
  `;
};
