import { theming } from '@emartech/ui-framework-utils';

const calculateColor = (color) => {
  if (!color) { return 'var(--token-chart-01-default)'; }

  const generatedToken = theming.generateToken(color, 'token');

  return generatedToken ? `var(${generatedToken})` : color;
};

export default calculateColor;
