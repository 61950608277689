import calculateColor from '../../libraries/calculate-color/index.js';

const renderData = chart => {
  if (chart.rawData.y !== null) {
    return `<td class="ec-table__data" style="color: ${calculateColor(chart.color)}">${chart.data.y}</td>`;
  }

  return `
    <td class="ec-table__data text-color-faint">
      <e-translation key="components.chart.tooltip.missingData"></e-translation>
    </td>`;
};

export default groups => {
  const classList = type => type === 'stack' ? 'ec-table__row ec-table__row-stack' : 'ec-table__row';

  return `
    ${Object.keys(groups).map(groupName => `
      <ec-tooltip-header class="ec-tooltip__header"><span>${groupName}</span></ec-tooltip-header>
      <ec-tooltip-content>
        <table class="ec-table">
          ${groups[groupName].map(chart => `
            <tr class="${classList(chart.type)}">
              <td class="ec-table__name">${chart.name}</td>
              ${renderData(chart)}
            </tr>`).join('')}
        </table>
      </ec-tooltip-content>
    `).join('')}
  `;
};
