import Diagram from '../index.js';
import Area from '../area/index.js';
import Column from '../column/index.js';
import Locator from '../../locator/index.js';

export default class Stack extends Diagram {
  constructor(options, scale, state, emitter, seriesStore) {
    super(options, scale, state, emitter, seriesStore);

    this._parts = [];
    this._locator = new Locator(this._scale);
    this._showLocator = false;

    this._render();
    this._addEventListeners();
  }

  update(options, state) {
    super.update(options, state);

    this._parts.forEach(part => {
      part.chart.update(part.options, this._state);
    });
  }

  disconnect() {
    this._parts.forEach(part => part.chart.disconnect());
    this._removeEventListeners();
  }

  _addEventListeners() {
    this._emitter.on('interaction.enter', this._moveLocator, this);
    this._emitter.on('interaction.leave', this._locator.hide, this._locator);
    this._emitter.on('interaction.move', this._moveLocator, this);
  }

  _removeEventListeners() {
    this._emitter.off('interaction.enter', this._moveLocator, this);
    this._emitter.off('interaction.leave', this._locator.hide, this._locator);
    this._emitter.off('interaction.move', this._moveLocator, this);
  }

  _moveLocator(...args) {
    if (this._showLocator && this._state.showTooltip) {
      this._locator.move(...args);
    }
  }

  _render() {
    this._createContainer('stack');

    this._container.selectAll('g').remove();

    if (!this._options.parts) { return; }

    this._parts = this._options.parts.map(part => {
      const chart = part.type === 'stackArea' ?
        new Area({ ...part, parent: this.options }, this._scale, this._state, this._emitter, this._seriesStore) :
        new Column({ ...part, parent: this.options }, this._scale, this._state, this._emitter, this._seriesStore);

      if (part.visible) {
        this._container.node().appendChild(chart.container.node());
      }

      return { chart, options: part };
    });

    this._showLocator = !!this._parts.filter(part => part.options.type === 'stackArea').length;

    if (this._showLocator) {
      this._locator.update(this._options, this._state);
      this._container.node().appendChild(this._locator.container.node());
    }
  }
}
