import AxisY from './base.js';

import { DEFAULT_TICK_PADDING } from './constants.js';

class AxisYRight extends AxisY {
  update(series, state) {
    const stateWithoutFormatter = Object.assign({}, state, {
      axisYFormatter: value => value
    });

    super.update(series, stateWithoutFormatter);
  }

  get _identifierAttr() {
    return 'axis-y-right';
  }

  _dataForYAxis(series) {
    return series.summaryForRightYAxis.map(d => d.y);
  }

  _alignScaleLabels() {
    this._container.attr('transform', `translate(${this._getContainerTranslateX()},0)`);
  }

  _getContainerTranslateX() {
    const whenFixedOffsetIsLarger = this._state.canvasWidth + this.width + DEFAULT_TICK_PADDING;
    const whenFixedOffsetIsSmaller = this._state.width - this._state.offset.left + DEFAULT_TICK_PADDING;
    return Math.min(whenFixedOffsetIsLarger, whenFixedOffsetIsSmaller);
  }
}

export default AxisYRight;
