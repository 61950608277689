import * as d3 from '../../libraries/d3/index.js';

const linePositionFix = 0.5;

class Baseline {
  constructor() {
    this.container = d3
      .select(document.createElementNS(d3.namespace('svg').space, 'g'))
      .attr('baseline', '');
    this._line = this.container.append('line');
    this._hidden = false;
  }

  update(scale, state) {
    const y = scale.y.scale(state.baseline) + linePositionFix;
    if (isNaN(y) || this._hidden) {
      this._line.attr('stroke-width', 0);
      return;
    }
    this._line
      .attr('x1', 0)
      .attr('x2', state.canvasWidth)
      .attr('y1', y)
      .attr('y2', y)
      .attr('stroke-width', this._calculateStrokeWidth(y, state.canvasHeight));
  }

  _calculateStrokeWidth(y, canvasHeight) {
    return y >= 0 && y <= canvasHeight + linePositionFix ? 1 : 0;
  }

  set hidden(value) {
    this._hidden = value;
  }

}

export default Baseline;
