import * as d3 from '../../../libraries/d3/index.js';
import Diagram from '../index.js';

const radius = 4;

export default class Plot extends Diagram {
  constructor(options, scale, state, emitter, seriesStore) {
    super(options, scale, state, emitter, seriesStore);

    this._render();
  }

  update(options, state) {
    super.update(options, state);

    this._plots
      .attr('stroke', this._options.color)
      .attr('cx', data => this._calculateX(data))
      .attr('cy', data => this._scale.y.scale(data.y));
  }

  disconnect() {
    this._plots.interrupt();
  }

  _render() {
    this._createContainer('plot');

    const dataset = this._options.summarizedData ? this._options.summarizedData : this._options.data;

    this._plots = this._container.selectAll('plot')
      .data(dataset.filter(this._filterDataRanges.bind(this)))
      .enter()
      .append('circle');

    this.update(this._options, this._state);

    this._plots
      .transition()
      .delay(this._animationDuration)
      .duration(this._animationDuration)
      .ease(d3.easeCubicInOut)
      .attrTween('r', () => {
        return time => time * radius;
      });
  }

  _calculateX(data) {
    const position = this._scale.x.getPositionOf(data.x);
    const offset = this._state.domainType === 'discrete' ? this._scale.x.scale.bandwidth() / 2 : 0;
    return position + offset;
  }

  _filterDataRanges(data, index, dataset) {
    if (this._hasMarkers && this._valueIsValid(data.y)) { return true; }

    return this._valueIsValid(data.y) &&
      (!this._valueIsValid(dataset[index - 1]?.y) || !this._valueIsValid(dataset[index + 1]?.y));
  }

  _valueIsValid(value) {
    return value !== null && value !== undefined;
  }
}
