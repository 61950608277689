import CustomEvent from 'custom-event';

import { EcSeriesLine } from '../series-line/index.js';

class EcSeriesStackArea extends EcSeriesLine {
  init() {
    super.init();

    this._state = Object.assign({}, this._state, { type: 'stackArea' });
  }

  static get observedAttributes() {
    return ['order'].concat(super.observedAttributes);
  }

  set order(value) {
    this._state.zIndex = parseInt(value);
    this._dispatchEvent();
  }

  disconnectedCallback() {
    this.dispatchEvent(new CustomEvent('stack.delete', { detail: this._state }));
  }

  _dispatchEvent() {
    if (!this._state.data || !this.parentNode) {
      return;
    }

    const event = new CustomEvent('stack.update', {
      bubbles: true,
      detail: this._state
    });
    this.dispatchEvent(event);
  }
}

window.customElements.define('ec-series-stack-area', EcSeriesStackArea);
