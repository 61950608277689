import * as importedD3 from '../../libraries/d3/index.js';

export default class InteractionLayer {
  constructor(emitter, d3 = importedD3) {
    this._rect = null;
    this._d3 = d3;
    this._container = this._d3.select(
      document.createElementNS(this._d3.namespace('svg').space, 'g')).attr('interaction-layer', '');
    this._emitter = emitter;
  }

  get container() {
    return this._container;
  }

  build() {
    this._container.select('rect').remove();
    this._rect = this._container.append('rect');

    this._container.on('mouseenter', this._onMouseEnter.bind(this));
    this._container.on('mouseleave', this._onMouseLeave.bind(this));
    this._container.on('mousemove', this._onMouseMove.bind(this));
  }

  update(size) {
    this._rect
      .attr('width', Math.max(0, size.width))
      .attr('height', Math.max(0, size.height));
  }

  _onMouseEnter(event) {
    this._emitter.emit('interaction.enter', this._getLocation(event));
  }

  _onMouseLeave(event) {
    this._emitter.emit('interaction.leave', this._getLocation(event));
  }

  _onMouseMove(event) {
    this._emitter.emit('interaction.move', this._getLocation(event));
  }

  _getLocation(event) {
    const [x, y] = this._d3.pointer(event, this._container.node());
    const [documentX, documentY] = this._d3.pointer(event, document.documentElement);
    return { x, y, documentX, documentY };
  }
}
