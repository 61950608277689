import { EcSeries } from '../series/index.js';

class EcSeriesStack extends EcSeries {
  init() {
    super.init();

    this._parts = [];
    this._data = [];
    this._state = Object.assign({ parts: this._parts }, this._state, { type: 'stack' });

    this.addEventListener('stack.update', this._updateParts);
    this._deleteParts = this._deleteParts.bind(this);
  }

  static get observedAttributes() {
    return ['padding'].concat(super.observedAttributes);
  }

  set padding(value) {
    this._state.padding = parseFloat(value);
    this._dispatchEvent();
  }

  _updateParts(event) {
    const index = this._parts.findIndex(part => part.uuid === event.detail.uuid);
    const options = Object.assign({}, event.detail);

    if (index === -1) {
      this._parts.push(options);
    } else {
      this._parts[index] = options;
    }

    this._parts.sort((a, b) => (a.zIndex > b.zIndex) ? 1 : -1);

    event.target.addEventListener('stack.delete', this._deleteParts);

    this._updateState();
  }

  _deleteParts(event) {
    const index = this._parts.findIndex(part => part.uuid === event.detail.uuid);
    this._parts.splice(index, 1);
    this._updateState();
  }

  _updateState() {
    if (!this._parts.length) {
      this._dispatchEvent();
      return;
    }

    const availableXValues = this._parts.reduce((values, part) => {
      const xValues = part.data.map(data => data.x);
      return [...new Set(values.concat(...xValues))];
    }, []);

    this._state.parts = this._parts.map(part => ({ ...part }));

    this._state.parts.forEach((part, partIndex) => {
      part.data = this._fillDataWithZero(part.data, availableXValues);
      part.summarizedData = part.data.map(data => {
        const ySummary = this._calculateSummary(data, partIndex);
        return { x: data.x, y: ySummary + data.y, y1: ySummary };
      });
    });

    this._state.data = this._state.parts[this._parts.length - 1].summarizedData.map(row => ({ x: row.x, y: row.y }));

    this._dispatchEvent();
  }

  _fillDataWithZero(partData, availableXValues) {
    partData = partData.map(data => ({ ...data }));

    const xValues = [...new Set(partData.map(data => data.x))];
    const difference = availableXValues.filter(x => !xValues.includes(x));

    if (difference.length) {
      difference.forEach(x => partData.push({ x, y: 0 }));
      return partData.sort((dataA, dataB) => ((dataA.x < dataB.x) ? -1 : ((dataA.x > dataB.x) ? 1 : 0)));
    }

    return partData;
  }

  _calculateSummary(data, partIndex) {
    return this._state.parts
      .slice(0, partIndex)
      .reduce((summary, current) => summary + current.data.find(row => row.x === data.x).y, 0);
  }
}

window.customElements.define('ec-series-stack', EcSeriesStack);
