import * as d3 from '../../../libraries/d3/index.js';
import Diagram from '../index.js';
import Plot from '../plot/index.js';
import Locator from '../../locator/index.js';

export default class Line extends Diagram {
  constructor(options, scale, state, emitter, seriesStore) {
    super(options, scale, state, emitter, seriesStore);

    this._markers = null;
    this._path = null;
    this._missingDataPaths = [];
    this._locator = new Locator(this._scale);

    this._render();
    this._addEventListeners();
  }

  update(options, state) {
    super.update(options, state);

    const line = this._calculateLine();
    const area = this._calculateMissingDataArea();
    const data = this._options.summarizedData ? this._options.summarizedData : this._options.data;
    const missingDataRanges = this._getMissingDataRanges(data);

    this._path
      .attr('d', line(data))
      .attr('stroke', this._options.color)
      .attr('line', '');

    this._missingDataPaths.forEach((path, index) => {
      path.attr('d', area(missingDataRanges[index]))
        .attr('fill', 'var(--token-neutral-default)')
        .style('opacity', '.1')
        .attr('missing-data', '');
    });

    if (this._options.lineStyle === 'dashed') {
      this._path.attr('stroke-dasharray', '4');
    }

    if (!this._options.summarizedData) {
      this._markers.update(this._options, this._state);
      this._locator.update(this._options, this._state);
    }

    return line;
  }

  disconnect() {
    this._path.interrupt();

    if (!this._options.summarizedData) {
      this._markers.disconnect();
    }

    this._removeEventListeners();
  }

  _createMarkers() {
    return new Plot(this._options, this._scale, this._state, this.emitter, this.seriesStore);
  }

  _moveLocator(...args) {
    if (!this._options.summarizedData && this._state.showTooltip) {
      this._locator.move(...args);
    }
  }

  _render() {
    this._createContainer('line');

    const dataset = this._options.summarizedData ? this._options.summarizedData : this._options.data;
    const missingDataRanges = this._getMissingDataRanges(dataset);

    this._missingDataPaths = new Array(missingDataRanges.length).fill(null).map(() => this._container.append('path'));

    this._path = this._container.append('path');

    if (!this._options.summarizedData) {
      this._markers = this._createMarkers();

      this._markers.container.attr('markers', '');
      this._container.node().appendChild(this._markers.container.node());

      this._container.node().appendChild(this._locator.container.node());
    }

    const line = this.update(this._options, this._state);
    const startData = dataset.map(data => ({
      x: data.x,
      y: this._scale.y.scale.invert(this._state.canvasHeight)
    }));

    this._path
      .transition()
      .duration(this._animationDuration)
      .ease(d3.easeCubicInOut)
      .attrTween('d', () => {
        const interpolator = d3.interpolateArray(startData, dataset);
        return time => line(interpolator(time));
      });
  }

  _addEventListeners() {
    this._emitter.on('interaction.enter', this._moveLocator, this);
    this._emitter.on('interaction.leave', this._locator.hide, this._locator);
    this._emitter.on('interaction.move', this._moveLocator, this);
  }

  _removeEventListeners() {
    this._emitter.off('interaction.enter', this._moveLocator, this);
    this._emitter.off('interaction.leave', this._locator.hide, this._locator);
    this._emitter.off('interaction.move', this._moveLocator, this);
  }

  _calculateLine() {
    const line = d3.line()
      .defined(data => data.y !== null)
      .x(data => this._calculateX(data))
      .y(data => this._scale.y.scale(data.y));

    if (this._options.interpolate) {
      line.curve(d3.curveMonotoneX);
    }

    return line;
  }

  _calculateMissingDataArea() {
    const area = d3.area()
      .x((data, index, original) => {
        let margin = 0;

        if (index === 0) {
          margin = 4;
        } else if (index === original.length - 1) {
          margin = -4;
        }

        return this._calculateX(data) + margin;
      })
      .y0(() => 0)
      .y1(() => this._state.canvasHeight);

    return area;
  }

  _calculateX(data) {
    const position = this._scale.x.getPositionOf(data.x);
    const offset = this._state.domainType === 'discrete' ? this._scale.x.scale.bandwidth() / 2 : 0;
    return position + offset;
  }

  _getMissingDataRanges(data) {
    const dataRanges = [];
    let activeDataRangeIndex = -1;

    data.forEach((datum, index) => {
      if (datum.y !== null && activeDataRangeIndex !== -1) {
        dataRanges[activeDataRangeIndex].push(datum);
        activeDataRangeIndex = -1;
        return;
      }

      if (datum.y === null) {
        if (activeDataRangeIndex === -1) {
          dataRanges.push([datum]);
          activeDataRangeIndex = dataRanges.length - 1;

          if (index > 0 && data[index - 1] !== null) {
            dataRanges[activeDataRangeIndex].unshift(data[index - 1]);
          }

          return;
        }

        dataRanges[activeDataRangeIndex].push(datum);
      }
    });

    return dataRanges;
  }
}
