import * as d3 from '../../../libraries/d3/index.js';
import Scale from '../../../libraries/scale/index.js';
import Formatter from '../../../libraries/formatter/index.js';
import { DEFAULT_TICK_PADDING, SUGGESTED_TICK_COUNT } from './constants.js';

const calculateLongestTick = ticks => {
  if (ticks.nodes().length === 0) {
    return 0;
  }

  const longestTickWidth = Math.max.apply(Math, ticks.nodes().map(tick => tick.getBoundingClientRect().width));
  return Math.round(longestTickWidth);
};

class AxisY {
  constructor() {
    this._tickPadding = DEFAULT_TICK_PADDING;
    this._state = {};
    this._data = [];
    this._scale = new Scale('y');
    this._formatter = new Formatter();
    this._defaultTickFormatter = value => this._formatter.format(this._formatter.formatY, value);
    this._tickFormatter = this._defaultTickFormatter;
    this._container = d3.select(document.createElementNS(d3.namespace('svg').space, 'g'))
      .attr(this._identifierAttr, '');
  }

  update(series, state) {
    this._state = state;
    this._formatter.formatY = series.formatY;
    this._formatter.currency = state.currency;
    this._tickFormatter = state.axisYFormatter || this._defaultTickFormatter;
    this._data = this._dataForYAxis(series);
    this._scale.update(this._data, state);
    this._draw();
  }

  get scale() {
    return this._scale;
  }

  get container() {
    return this._container;
  }

  get width() {
    if (this._isHiddenOrEmpty) { return 0; }
    const ticks = this._container.selectAll('.tick text');
    const longestTickWidth = calculateLongestTick(ticks);
    return longestTickWidth + this._tickPadding;
  }

  set hidden(value) {
    this._hidden = value;
    this._tickPadding = value ? 0 : DEFAULT_TICK_PADDING;
    this._draw();
  }

  get _isHiddenOrEmpty() {
    return this._hidden || !this._data.length;
  }

  get _renderAxis() {
    return d3.axisLeft(this._scale.scale)
      .ticks(SUGGESTED_TICK_COUNT)
      .tickFormat(value => this._tickFormatter(value));
  }

  _draw() {
    if (!this._scale.scale) {
      return;
    }

    this._container.call(this._renderAxis);
    this._setTickSize();
    this._alignScaleLabels();

    if (this._hidden) {
      this._container.selectAll('.tick').remove();
    }

    this._renderLabelBackgrounds();
    this._container.select('.domain').remove();
  }

  _setTickSize() {
    this._container.call(
      this._renderAxis
        .tickPadding(this._tickPadding)
        .tickSize(0, 0)
    );
  }

  _renderLabelBackgrounds() {
    const backgroundPadding = { x: 2, y: 1 };

    this._container.selectAll('.tick').each(function() {
      const tick = d3.select(this);
      const text = tick.select('text');
      const bBox = text.node().getBBox();

      const existingBackground = tick.select('rect');
      const background = existingBackground.size() ? existingBackground : tick.insert('rect', ':first-child');

      background
        .attr('x', bBox.x - backgroundPadding.x)
        .attr('y', bBox.y - backgroundPadding.y)
        .attr('width', bBox.width + 2 * backgroundPadding.x)
        .attr('height', bBox.height + 2 * backgroundPadding.y)
        .attr('rx', bBox.height / 2 + backgroundPadding.y)
        .attr('transform', text.attr('transform'));
    });
  }

  _dataForYAxis() {
    throw new Error('Not Implemented');
  }

  _alignScaleLabels() {
    throw new Error('Not Implemented');
  }
}

export default AxisY;
