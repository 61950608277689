export default {
  animationDuration: 550,
  defaultCurrencySymbol: '€',
  tooltip: {
    offset: {
      time: 15,
      discrete: 5
    }
  }
};
