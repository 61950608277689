import * as d3 from '../../libraries/d3/index.js';

class Gridline {
  constructor() {
    this._container = d3
      .select(document.createElementNS(d3.namespace('svg').space, 'g'))
      .attr('gridline', '');
  }

  get container() {
    return this._container;
  }

  set hidden(value) {
    this._container.attr('visibility', value ? 'hidden' : 'visible');
  }

  update(scale, state) {
    this._container.call(
      d3.axisLeft(scale.scale)
        .ticks(3)
        .tickSize(state.width - state.offset.right, 0)
    );
    this._container.attr('transform', `translate(${state.canvasWidth},0)`);
    this._container.selectAll('.tick text').remove();
    this._removeVerticalDomain();
  }

  _removeVerticalDomain() {
    const domain = this._container.select('.domain');
    const vertical = domain.attr('d').indexOf('V');
    const horizontalDomain = domain.attr('d').substring(0, vertical);
    domain.attr('d', horizontalDomain);
  }
}

export default Gridline;
