import Diagram from '../index.js';
import * as d3 from '../../../libraries/d3/index.js';
import calculateColor from '../../../libraries/calculate-color/index.js';

const forceBetweeenBoundaries = (value, min, max) => value < min ? min : (value > max ? max : value);
const PART_GAP = 2;

class Column extends Diagram {
  constructor(options, scale, state, emitter, seriesStore) {
    super(options, scale, state, emitter, seriesStore);

    this._render();
    this._addEventListeners();
  }

  update(options, state) {
    super.update(options, state);

    this._columns.interrupt();
    this._calculateBaselineCoordinate();
    this._columns = this._columns
      .attr('x', data => options.groupSize ?
        this._scale.x.getPositionOf(data.x) + PART_GAP / 2 +
        (this._scale.x.scale.bandwidth() / options.groupSize) * (options.xOffset) :
        this._scale.x.getPositionOf(data.x)
      )
      .attr('width', () => options.groupSize ?
        (this._scale.x.scale.bandwidth() / options.groupSize) - PART_GAP :
        this._scale.x.scale.bandwidth()
      )
      .attr('y', data => data.y1 ?
        (this._state.canvasHeight - this._getColumnHeight(data.y)) :
        this._getColumnTop(data.y)
      )
      .attr('height', data => data.y1 ? this._getColumnHeight(data.y - data.y1) : this._getColumnHeight(data.y));

    this._colorColumns();
  }

  disconnect() {
    this._columns.interrupt();
    this._removeEventListeners();
  }

  _render() {
    this._createContainer('column');

    const dataset = this._options.summarizedData ? this._options.summarizedData : this._options.data;

    this._columns = this._container.selectAll('rect')
      .data(dataset)
      .enter()
      .append('rect');

    this.update(this._options, this._state);

    this._columns
      .attr('y', this._baselineCoordinate)
      .attr('height', 0)
      .transition()
      .delay((data, index, elementsInSelection) => {
        return this._animationDuration * index / elementsInSelection.length;
      })
      .duration(this._animationDuration)
      .ease(d3.easeCubicInOut)
      .attr('y', data => data.y1 ?
        this._state.canvasHeight - this._getColumnHeight(data.y) :
        this._getColumnTop(data.y)
      )
      .attr('height', data => data.y1 ? this._getColumnHeight(data.y - data.y1) : this._getColumnHeight(data.y));
  }

  _calculateBaselineCoordinate() {
    const baselineCoordinate = this._scale.y.scale(this._state.baseline);
    this._baselineCoordinate = forceBetweeenBoundaries(baselineCoordinate, 0, this._state.canvasHeight);
  }

  _getColumnTop(y) {
    return Math.min(this._scale.y.getPositionOf(y), this._baselineCoordinate);
  }

  _getColumnHeight(y) {
    return Math.abs(this._baselineCoordinate - this._scale.y.scale(y));
  }

  _colorColumns() {
    this._columns.attr('fill', data => (data.color && calculateColor(data.color)) || this._options.color);
    this._columns.attr('highlight', null);
    this._container.select('rect:last-child').attr('highlight-last', null);

    if (this._options.highlightLast && !this._options.summarizedData) {
      this._highlightLastColumn();
    }
  }

  _highlightColumn(position) {
    if (!this._state.showTooltip) { return; }

    this._colorColumns();

    const closestIndex = this._scale.x.getClosestIndex(position.x);
    this._columns.nodes()[closestIndex].setAttribute('highlight', '');
  }

  _highlightLastColumn() {
    this._container.select('rect:last-child').attr('highlight-last', '');
  }

  _addEventListeners() {
    this._emitter.on('interaction.move', this._highlightColumn, this);
    this._emitter.on('interaction.leave', this._colorColumns, this);
  }

  _removeEventListeners() {
    this._emitter.off('interaction.move', this._highlightColumn, this);
    this._emitter.off('interaction.leave', this._colorColumns, this);
  }
}

export default Column;
