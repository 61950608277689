import Diagram from '../index.js';
import Column from '../column/index.js';

export default class Group extends Diagram {
  constructor(options, scale, state, emitter, seriesStore) {
    super(options, scale, state, emitter, seriesStore);

    this._parts = [];

    this._render();
  }

  update(options, state) {
    super.update(options, state);

    this._parts.forEach(part => {
      part.chart.update(part.options, this._state);
    });
  }

  disconnect() {
    this._parts.forEach(part => part.chart.disconnect());
  }

  _render() {
    this._createContainer('group');

    this._container.selectAll('g').remove();

    if (!this._options.parts) { return; }

    this._parts = this._options.parts.map(part => {
      const chart = new Column(
        { ...part, parent: this.options },
        this._scale,
        this._state,
        this._emitter,
        this._seriesStore
      );

      if (part.visible) {
        this._container.node().appendChild(chart.container.node());
      }

      return { chart, options: part };
    });
  }
}
