import { computePosition, flip, offset } from '@floating-ui/dom';

import template from './template.js';
import templateCustom from './template-custom.js';
import Formatter from '../../libraries/formatter/index.js';
import config from '../../config/index.js';

class Tooltip {
  constructor(seriesStore, scale, emitter) {
    this._scale = scale;
    this._state = {};
    this._seriesStore = seriesStore;
    this._referenceBounding = { top: 0, right: 0, bottom: 0, left: 0, width: 0 };
    this._virtualReferenceElement = {
      getBoundingClientRect: () => {
        return {
          top: this._referenceBounding.top,
          left: this._referenceBounding.right,
          bottom: this._referenceBounding.bottom,
          right: this._referenceBounding.left,
          width: this._referenceBounding.width,
          height: 0,
          x: 0,
          y: 0
        };
      }
    };
    this._boundaryElement = document.createElement('div');
    this._popper = this._addPopper();
    this._formatter = new Formatter();
    this._emitter = emitter;

    this._closestDataOnXAxis = false;

    this._emitter.on('interaction.move', this._onMouseMove.bind(this));
    this._emitter.on('interaction.enter', this._onMouseEnter.bind(this));
    this._emitter.on('interaction.leave', this._onMouseLeave.bind(this));
  }

  update(state) {
    this._state = state;
    this._formatter.currency = this._state.currency;
  }

  remove() {
    this._popper.remove();
  }

  _isRenderPossible() {
    return this._seriesStore && this._state.showTooltip && this._scale;
  }

  _render() {
    if (!this._isRenderPossible()) {
      return;
    }

    const charts = this._seriesStore.dataOrderedByUpdateEvent
      .filter(chart => !chart.hiddenInTooltip && chart.data.length !== 0)
      .sort((a, b) => a.type === 'group' ? -1 : (b.type === 'group' ? 1 : 0))
      .sort((a, b) => a.type === 'stack' ? -1 : (b.type === 'stack' ? 1 : 0));

    const previousClosestDataOnXAxis = this._closestDataOnXAxis;
    this._closestDataOnXAxis = this._getClosestData();

    this._updatePopper(charts);

    if (previousClosestDataOnXAxis !== this._closestDataOnXAxis) {
      this._updateData(charts);
    }
  }

  _onMouseMove(position) {
    this._position = position;
    this._render();
  }

  _onMouseEnter(position) {
    if (!this._isRenderPossible()) {
      return;
    }

    this._position = position;
    this._closestDataOnXAxis = false;
    this._render();

    document.body.appendChild(this._boundaryElement);
    document.body.appendChild(this._popper);
  }

  _onMouseLeave() {
    if (!this._popper.parentNode) {
      return;
    }

    if (this._boundaryElement.parentNode) {
      this._boundaryElement.parentNode.removeChild(this._boundaryElement);
    }

    document.body.removeChild(this._popper);
  }

  _getClosestData() {
    return this._scale.x.getClosestData(this._position.x);
  }

  _addPopper() {
    const popperElement = document.createElement('ec-tooltip');
    popperElement.className = 'ec-tooltip';

    this._boundaryElement.className = this._boundaryElementClassName();

    return popperElement;
  }

  _updatePopper(charts) {
    this._togglePopperVisibility(charts);
    const isDiscrete = this._state.domainType === 'discrete';
    const tooltipOffset = isDiscrete ? config.tooltip.offset.discrete : config.tooltip.offset.time;
    const referenceWidth = isDiscrete ? this._scale.x.scale.bandwidth() : 0;
    const positionX = this._scale.x.getClosestPosition(this._closestDataOnXAxis);
    const mousePositionY = this._position.documentY;
    const mousePositionX = positionX + (this._position.documentX - this._position.x);

    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

    this._referenceBounding.left = mousePositionX - scrollLeft;
    this._referenceBounding.right = mousePositionX - scrollLeft;
    this._referenceBounding.top = mousePositionY - scrollTop;
    this._referenceBounding.bottom = mousePositionY - scrollTop;
    this._referenceBounding.width = referenceWidth;

    computePosition(this._virtualReferenceElement, this._popper, {
      placement: 'left',
      middleware: [
        offset(tooltipOffset),
        flip({ boundary: this._boundaryElement })
      ]
    }).then(({ x, y }) => {
      Object.assign(this._popper.style, {
        transform: `translate(${x}px, ${y}px)`,
        left: '0',
        top: '0',
        position: 'absolute'
      });
    });
  }

  _boundaryElementClassName() {
    const className = [
      'e-popover_boundary',
      ...(document.querySelector('.e-topnav') ? ['e-popover_boundary-header'] : []),
      ...(document.querySelector('e-navigation') ? ['e-popover_boundary-navigation'] : []),
      ...(document.querySelector('.e-steps') ? ['e-popover_boundary-footer'] : [])
    ];

    return className.join(' ');
  }

  _updateData(charts) {
    if (this._state.customTooltip) {
      this._popper.innerHTML = this._renderCustomTooltip();
    } else {
      this._popper.innerHTML = this._renderTooltip(charts);
    }
  }

  _renderTooltip(charts) {
    const currentData = charts.map(chart => {
      const tooltipData = chart.data.filter(data => data.x === this._closestDataOnXAxis)[0];
      const color = tooltipData && tooltipData.color ? tooltipData.color : chart.color;

      return {
        type: chart.type,
        name: chart.name,
        color: color,
        data: this._formatData(tooltipData, this._state.formatX, chart.formatY),
        rawData: tooltipData
      };
    });

    const groups = currentData.reduce((base, current) => {
      if (current.type === 'group') { return base; }
      if (!current.data) { return base; }

      const groupTitle = this._formatHeader(current.data.x);

      base[groupTitle] = base[groupTitle] || [];
      base[groupTitle].push(current);

      return base;
    }, {});

    return template(groups);
  }

  _renderCustomTooltip() {
    const closestTooltipData = this._state.customTooltip[this._closestDataOnXAxis];
    const tooltipRows = closestTooltipData ? closestTooltipData.rows : [];

    return templateCustom(tooltipRows);
  }

  _formatHeader(value) {
    return this._state.tooltipHeaderFormatter ? this._state.tooltipHeaderFormatter(value) : value;
  }

  _formatData(data, formatX, formatY) {
    if (!data) { return; }

    this._formatter.dateFormat = this._state.tooltipXDateFormat;
    this._formatter.formatX = formatX;
    this._formatter.formatY = formatY;

    if (data.tooltip) {
      return {
        x: this._formatter.transformX(data.tooltip.x || data.x),
        y: this._formatter.transformY(data.tooltip.y || data.y)
      };
    }

    return {
      x: this._formatter.transformX(data.x),
      y: this._formatter.transformY(data.y)
    };
  }

  _togglePopperVisibility(charts) {
    const method = charts.length === 0 ? 'add' : 'remove';
    this._popper.classList[method]('ec-tooltip-hidden');
  }
}

export default Tooltip;
